<template>
  <ui-component-modal
    modalTitle=""
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :onClickCancel="onClickCancel"
    :onClickSave="saveSortOrder"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <div
        v-for="(cat, cIndex) in categories"
        :key="cIndex"
        class="has-margin-bottom"
      >
        <p class="title">
          {{
            $t(
              'Components.Reservation.ReservationOptions.Category_' +
                cat.CategoryId
            )
          }}
        </p>

        <draggable
          :group="'cat_' + cat.CategoryId"
          :list="cat.Options"
          sort="true"
          draggable=".resOption"
        >
          <div
            v-for="resOption in cat.Options.filter(
              (o) => o.CategoryId === cat.CategoryId
            )"
            :key="resOption.Id"
            class="resOption card has-margin-bottom has-padding"
            style="cursor: move;"
          >
            <span class="icon handle">
              <font-awesome-icon :icon="['fas', 'align-justify']" />
            </span>
            <span class="has-margin-left">{{ resOption.Name }}</span>
          </div>
        </draggable>
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'
import draggable from 'vuedraggable'
import reservationProvider from '@/providers/reservation'

export default {
  components: {
    draggable,
  },

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,

      categories: [],
      options: [],
    }
  },

  computed: {
    ...mapState('reservationStore', ['reservation']),
  },

  created() {
    this.options = JSON.parse(JSON.stringify(this.reservation.Options))

    this.setupCategories()
  },

  methods: {
    ...mapMutations('reservationStore', ['setReservation']),

    setupCategories() {
      let categories = []

      for (let i = 0; i < this.options.length; i++) {
        let option = this.options[i]

        if (
          categories.findIndex((c) => c.CategoryId === option.CategoryId) === -1
        ) {
          categories.push({
            CategoryId: option.CategoryId,
            Options: this.options.filter(
              (o) => o.CategoryId === option.CategoryId
            ),
          })
        }
      }

      this.categories = categories.sort()
    },

    saveSortOrder() {
      let self = this

      if (!self.isSaving) {
        self.isSaving = true

        let reservationOptionIds = []

        for (let i = 0; i < this.categories.length; i++) {
          let cat = this.categories[i]

          for (let j = 0; j < cat.Options.length; j++) {
            let opt = cat.Options[j]
            reservationOptionIds.push(opt.Id)
          }
        }

        reservationProvider.methods
          .updateReservationOptionSortOrder(
            this.reservation.Id,
            reservationOptionIds
          )
          .then((response) => {
            if (response.status === 200) {
              // Update reservation store
              self.setReservation(response.data)

              // Send message to display toast on screen
              EventBus.$emit('showToast', {
                type: 'info',
                message: this.$t('General.Toast_ReservationUpdated'),
              })

              self.onClickCancel()
            }
          })

        self.isSaving = false
      }
    },
  },
}
</script>
